// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APP_URL: 'test.freshstock.in',
  PARSE_APP_ID: 'N7Pp9hE3ukJyrKN6nQBgSceZQPBsp16aMOb1IyWz',
  PARSE_JS_KEY: 'KUg2pGDURqcPmWDOW9RZFyjEPY4iTP3xj41s5Rbg',
  PARSE_REST_API_KEY: 'GJXpXlXj3O33PKQjZCBsZN1TiWkMC9IPmFVxpgg7',
  serverURL: 'https://parseapi.back4app.com',
  FACEBOOK_API_URL: 'https://graph.facebook.com',
  FACEBOOK_APP_ID: '2205998569723352',
  FACEBOOK_CLIENT_SECRET: 'a15a91c0b46a84c5e195f10aec94b23a',
  FACEBOOK_APP_SECRET: '4c2a22f6477db77d7b24f02d0d75a13c',
  FACEBOOK_REDIRECT_URL: 'https://test.freshstock.in/account/oauth/process',
  RAZORPAY_API_URL: 'https://api.razorpay.com/v1',
  RAZORPAY_API_KEY: 'rzp_test_gfrYIWgQa5ZWLq',
  MIXPANEL_TOKEN: '6cc4d385cd8994d4ed6f3f5d12a364a9',
  MIXPANEL_TRACK_PREFIX: 'WEB: ',
  FIREBASE: {
    apiKey: "AIzaSyDoT4AWfgUcffoiunPOiPmv574JydK5y_I",
    authDomain: "project-1578038047940958237.firebaseapp.com",
    databaseURL: "https://project-1578038047940958237.firebaseio.com",
    projectId: "project-1578038047940958237",
    storageBucket: "project-1578038047940958237.appspot.com",
    messagingSenderId: "51180419220",
    appId: "1:51180419220:web:0df39acb7fa5134d2edc81",
    measurementId: "G-02XT4HG7KG"
  },
  FIRESTORE_ERROR_COLLECTION: 'ff_web_error_log',
  FIRESTORE_ERROR_PREFIX: '',
  baseHref: '/test/',
  COOKIE_SECURE: true
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
